import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import aiChatStarsIcon from '@/components/aiChatStarsIcon.vue';
import chatGPTIcon from '@/components/chatGPTIcon.vue';
import pptIcon from '@/components/pptIcon.vue';
import cannesLionsLogo from '@/components/cannesLionsLogo.vue';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      aiChatStars: {
        component: aiChatStarsIcon,
      },
      chatGPT: {
        component: chatGPTIcon,
      },
      pptIcon: {
        component: pptIcon,
      },
      cannesLions: {
        component: cannesLionsLogo,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#6b15d5',
        secondary: '#607d8b',
        accent: '#ffc107',
        error: '#ff916f',
        errortext: '#cn262a',
        warning: '#ff9800',
        info: '#00bcd4',
        success: '#8bc34a',
        anchor: '#cbcbcb',
        brandcolour: '#6b15d5',
        consolebusy: '#fff1d6',
        consolebusytext: 'c58a28',
        consolesuccess: '#e3f2e3',
        consolesuccesstext: '#307b34',
        confidential: '#CB262A',
      },
      dark: {
        primary: '#6b15d5',
        secondary: '#607d8b',
        accent: '#ffc107',
        error: '#ff916f',
        errortext: '#cn262a',
        warning: '#ff9800',
        info: '#00bcd4',
        success: '#8bc34a',
        anchor: '#cbcbcb',
        brandcolour: '#6b15d5',
        consolebusy: '#fff1d6',
        consolebusytext: 'c58a28',
        consolesuccess: '#e3f2e3',
        consolesuccesstext: '#307b34',
        confidential: '#CB262A',
      },
    },
  },
});
