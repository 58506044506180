import axios from 'axios';

class StarfishAssistant {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  endpoint = 'v2/assistant';

  axiosClient = axios;

  /**
   *
   * @param http {AxiosInstance}
   */
  constructor(http) {
    this.http = http;
  }

  /**
   *
   * @param conversation{AssistantConversation}
   * @returns {Promise<AssistantConversation>}
   */
  async ask(conversation) {
    try {
      conversation.removeOpenAIErrorMessages();
      
      console.log(conversation);
      const { data } = await this.http.post(`${this.endpoint}/ask`, conversation);

      if (conversation.id === '') {
        conversation.id = data.id;
      }

      // const { choices } = data;

      // (choices || []).forEach(({ message }) => {
      //   conversation.addAssistantMessage(message.content);
      // });
      
      const { content } = data;
      
      (content || []).forEach((contentItem) => {
        conversation.addAssistantMessage(contentItem.text);
      });

      return conversation;
    } catch (e) {
      console.log(e);
      const { errorMessage } = e.response.data;
      conversation.addErrorMessage(errorMessage);
      return conversation;
    }
  }

  async askRag({ 
    query,
    conversation,
    conversationSteram, 
    endpointInfo,
  }) {
    try {
      const { deploymentName, aiendpoint } = endpointInfo;
      const { 
        endpointUrl, 
        authorizationKey, 
        apimEndpointUrl, 
        apimSubscriptionKey,
      } = aiendpoint;
      const requestBody = conversationSteram; 
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorizationKey}`,
        'azureml-model-deployment': deploymentName,
        'ocp-apim-subscription-key': apimSubscriptionKey,
      };

      const response = await this.axiosClient.post(apimEndpointUrl, requestBody, { headers });
      let { answer } = response.data;
      const { documents } = response.data;

      const docsInfo = documents.map((doc) => ({
        docId: doc.documentid,
        title: doc.title,
      }));

      const uniqueDocs = docsInfo.filter(
        (doc, index, self) => index === self.findIndex((d) => d.docId === doc.docId),
      );

      const documentLinks = this.getContentPageUrls(uniqueDocs); 
      const documentLinksHtml = documentLinks.map((link) => `<li>${link}</li>`);
      if (documentLinksHtml?.length > 0) {
        answer = `${answer} <br><br>Source:<br><ul>${documentLinksHtml.join('')}</ul>`;
      }
      
      conversation.addAssistantMarkdownMessage(answer);
      conversationSteram.addMessage(query, answer);
      return conversation;
    } catch (e) {
      console.log(e);
      const { error } = e;
      conversation.addErrorMessage(error?.message);
      return conversation;
    }
  }

  async indexCollections({ type, siteId }) {
    const { data } = await this.http.get(`${this.endpoint}/sites/${siteId}/collections/${type}`);
    return data;
  }

  getContentPageUrls(docsInfo) {
    this; // Dummy `this` to avoid ESLint warning
    let links = [];
    const path = window.location.pathname;
    const match = path.match(/^\/sites\/(\d+)/);
    
    if (match) {
      const siteId = match[1];

      links = docsInfo.map((doc) => ({
        title: doc.title,
        path: `/sites/${siteId}/content/${doc.docId}/home`,
      }));
    } else {
      console.log('Site ID not found in the URL');
    }

    const hrefs = links.map((link) => `<a href="${link.path}" target="_blank">${link.title}</a>`);
    return hrefs;
  }
}

export default StarfishAssistant;
