import { searchParamFactory } from '@/lib/search/discovery/classes/SearchParameters';
import WordsDateFilter from '@/lib/search/discovery/classes/searchFilters/WordsDateFilter';
import { mapActions, mapGetters, mapState } from 'vuex';
import DocumentAssetsActionTypes from '@/documentAssets/store/DocumentAssetsActionTypes';
import SearchLogInput, { SearchLocations } from '@/lib/search/discovery/models/SearchLogInput';
import dashboardMixin from '@/components/dashboard/mixins/dashboardMixin';
import sitesMixin from '@/views/mixins/sitesMixin';

export default {
  mixins: [
    dashboardMixin,
    sitesMixin,
  ],
  data: () => ({
    query: '',
    isEnterprise: false,
    isVisible: true,
    metricFilters: [
      'uploader/organizations',
      'uploader/clients',
      'uploader/content_types',
    ],
    selectedFacets: [],
    searchParams: searchParamFactory((params) => {
      params.isSemanticSearch = true;
      return params;
    }),
    dateFilter: new WordsDateFilter('metadata_storage_last_modified'),
    searchText: '',
    fullTextQuery: false,
  }),
  computed: {
    ...mapGetters('common', [
      'userFirstName',
    ]),
    ...mapGetters('discovery', [
      'baseMetricFilters',
      'facetList',
    ]),
    ...mapState('common', [
      'indexPermissions',
      'userDeployments',
    ]),
    deployment() {
      return this.userDeployments.currentDeployment;
    },
    deploymentName() {
      return (this.deployment?.name || 'default').toLowerCase();
    },
    currentIndexName() {
      return this.currentDeploymentIndex?.name;
    },
    theme() {
      return this.currentDeploymentIndex?.theme;
    },
    heroImage() {
      // return this.theme?.heroImage || collectionThemes.defaultThemeProperty().heroImage;
      return this.settingValue('featuredContentImage');
    },
    heroImageMobile() {
      return this.settingValue('featuredContentImageMobile');
    },
    heroAspectRatio() {
      return this.$vuetify.breakpoint.xsOnly ? '3.4133405861' : '';
    },
    heroHeight() {
      /* if (this.$vuetify.breakpoint.smAndUp) {
        return this.template === 'compact' ? 120 : 360;
      }

      return 'auto';
      */
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 110;
        case 'sm': // fallthrough
        case 'md': return this.template === 'compact' ? 120 : 180;
        case 'lg': // fallthrough
        case 'xl': return this.template === 'compact' ? 120 : 360;
        default: return 'auto';
      }
    },
    showFiltersPanelOnHomePage() {
      return this.template === 'compact';
    },
    showHeader() {
      return !(this.$route.meta?.hideHeader);
    },
    showExportButton() {
      return this.indexPermissions.canDo('exportReports');
    },
    showSearch() {
      return this.template !== 'compact';
    },
    showFilters() {
      return this.$route.meta.showFilters;
    },
    facets() {
      if (this.baseMetricFilters == null) {
        return [];
      }

      return this.metricFilters.map((key) => this.baseMetricFilters.getFacetWithKey(key));
    },
    shouldRetrieveDashboardInsights() {
      return this.$route.meta.shouldRetrieveDashboardInsights || false;
    },
    shouldRetrieveMetrics() {
      return this.$route.meta.shouldRetrieveMetrics || false;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    topic() {
      return this.settingValue('topic');
    },
    title() {
      return this.settingValue('title');
    },
    mobileTitle() {
      return this.settingValue('mobileTitle') || this.title;
    },
    description() {
      return this.settingValue('description');
    },
    footer() {
      return this.settingValue('footer');
    },
    userGuide() {
      return this.settingValue('userGuide');
    },
    template() {
      const templateName = this.$route.query?.template;

      if (templateName) {
        return templateName;
      }
      // return this.selectedDeploymentIndex?.template || 'default';

      return this.currentDeploymentIndex?.template || 'default';
    },
    templateClass() {
      return `template-${this.template}`;
    },
    /**
     * If the current page is home page, then we start a search. otherwise we filter the metrics
     */
    shouldGoToSearchPage() {
      const routeName = this.$route.name;
      return routeName === 'dashboard-home';
    },
    semantic: {
      get() {
        return this.searchParams.isSemanticSearch;
      },
      set(value) {
        this.searchParams.isSemanticSearch = value;
      },
    },
    showIndexActions() {
      return this.indexPermissions.canDo('editDeploymentIndex');
    },
  },
  methods: {
    ...mapActions('common', [
      'checkForDeployments',
      'setUserUserSelectedIndex',
      'updateSearchLog',
    ]),
    ...mapActions('discovery', [
      'suggestions',
      'ciSearch',
      'getMetrics',
      'getFeaturedCollections',
      'getFeaturedGroups',
      'getDashboardInsights',
    ]),
    ...mapActions('assets', [
      `${DocumentAssetsActionTypes.getAssetsForDashboard}`,
      'fetchAssetStreams',
    ]),

    async search() {
      await this.updateSearchLog(new SearchLogInput(SearchLocations.HOME, this.$route.name));
      this.facetList.clear();
      const indexId = this.currentDeploymentIndexId;
      const params = {
        indexId,
      };
      this.$router.push({
        name: 'indexSearch',
        params,
        query: { q: encodeURI(this.query), semantic: false, hybrid: false },
      });
    },
    /* visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
      const element = document.getElementById('app');
      const arr = element.className.split(' ');

      console.log(entry);

      if (!this.isVisible && arr.indexOf('stickmenubar') === -1) {
        element.className += ' stickmenubar';
      } else {
        element.className = element.className.replace(/\b stickmenubar\b/g, '');
      }
    }, */
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
      const element = document.getElementById('app');
      const arr = element.className.split(' ');

      console.log(entry);

      if (!this.isVisible && arr.indexOf('stickmenubar') === -1) {
        element.className += ' stickmenubar';
      } else {
        element.className = element.className.replace(/\b stickmenubar\b/g, '');
      }
    },
    filterSelected() {
      this.baseMetricFilters.updatedSelectedFacets();
      this.searchParams.isfullTextQuery = this.fullTextQuery;
      this.searchParams.q = this.searchText;

      if (this.shouldGoToSearchPage) {
        this.gotoSearchPage();
      } else {
        this.filterDashboardMetrics();
      }
    },
    onDateFilterChanged(value) {
      this.baseMetricFilters.addFacetToList(value);
      this.filterSelected();
    },
    insightTypeChanged() {
      this.searchParams.documentsPerPage = 2000;
      this.filterSelected();
    },
    filterDashboardMetrics() {
      this.selectedFacets = this.baseMetricFilters.selectedFacets;
      this.searchParams.filters = this.selectedFacets;
      if (this.shouldRetrieveMetrics) {
        this.getMetrics({ initialize: false, searchParams: this.searchParams });
      }

      if (this.shouldRetrieveDashboardInsights) {
        this.getDashboardInsights(this.searchParams);
      }
    },
    gotoSearchPage() {
      const searchText = this.searchParams?.searchText || '';

      const exactPhrase = this.searchParams?.isfullTextQuery;
      const semantic = this.searchParams?.isSemanticSearch;
      const indexId = this.currentDeploymentIndexId;

      const parameters = { indexId };

      const q = (searchText && { q: searchText, exactPhrase, semantic }) || {};

      const query = this.baseMetricFilters.updateQuery(q);

      this.$router.push({
        name: 'indexSearch', query, parameters,
      });
    },
    clearSearchString() {
      this.searchText = '';
      this.filterSelected();
    },
    settingValue(field) {
      if (this.currentDeploymentIndex != null) {
        return this.currentDeploymentIndex.customizationSettings?.getSetting(field)?.value || '';
      }
      return '';
    },
    getTheIndex() {
      const { indexId } = this.$route.params;
      const index = this.userDeployments?.indexWithId(indexId);

      return index;
    },
    async onIndexChanged(index) {
      await this.initialiseSiteChange(index);
      // await this.setUserUserSelectedIndex(index);
      // await this.initialiseDashboard();
      // await this.$router.push({ name: 'defaultHome' });
    },
  },
};
