<template>
  <div>

    <!-- temporary removed, added v-click-outside, but still race condition and it's closing
         prematurely -->
    <v-navigation-drawer
        ref="rightPanelRef"
        v-model="rightPanelLocal"
        clipped
        app
        right
        disable-resize-watcher
        class="contextPanel"
        :stateless="true"
        :width="$vuetify.breakpoint.smAndUp ? 400 : 256"
      >
      <v-card
          flat
          tile
          class="d-flex right-panel-header-bar"
          width="100%"
          height="50px"
        >
        <v-row align="center" no-gutters="">
          <v-col
            class="panel-text text-uppercase font-weight-bold
            flex-grow-1 flex-shrink-0
            ">
            <v-icon size="28" class="material-symbols-outlined filled mx-2 panel-icon">upload</v-icon>
            {{ currentRouteName }}
          </v-col>
          <v-col
            class="flex-grow-0 flex-shrink-1 pr-4"
          ><v-btn class="btn_standout panel-close" icon
        height="48" width="48"
         @click="close">
          <v-icon class="material-symbols-outlined"
             size="28"
          >close</v-icon>
          </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <router-view name="rightpanel"></router-view>

    </v-navigation-drawer>

  </div>
</template>

<script>
import {
  mapGetters, mapMutations, mapActions, mapState,
} from 'vuex';
import router from '@/router';

export default {
  data: () => ({
  }),
  computed: {
    ...mapGetters('common', [
      'rightPanel',
    ]),
    ...mapState('common', [
      'appBusy',
    ]),
    currentRouteName() {
      if (this.$route) {
        return this.$route.meta.humanReadableName;
      }
      return '';
    },
    rightPanelLocal: {
      get() {
        console.log('getting right panel state');
        console.log(this.rightPanel);
        return this.rightPanel;
      },
      set(value) {
        console.log('RightSideRouterView ---- in right panel local SETTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT');
        console.log(value);
        console.log(this.rightPanel);
        // do nothing for now
        // this.setRightPanel(value);
      },
    },
  },
  methods: {
    ...mapMutations('common', [
      // 'closeRightPanel',
    ]),
    ...mapActions('common', [
      'closeRightPanel',
    ]),
    close() {
      this.closeRightPanel();
    },

    closeRightPanel() {
      if (!this.appBusy) {
        router.push(router.currentRoute.meta.parentpath);
      }
    },
  },
};
</script>
