import SystemMessage from './SystemMessage';
import UserMessage from './UserMessage';
import AssistantMessage from './AssistantMessage';
import ChatMessage from './ChatMessage';

class AssistantConversation {
  id = '';

  /**
   *
   * @type {[ChatMessage]}
   */
  messages = [];

  addSystemMessage(message) {
    this.messages.push(new SystemMessage(message));
    return this;
  }

  addUserMessage(message) {
    this.messages.push(new UserMessage(message));
    return this;
  }

  addAssistantMessage(message) {
    this.messages.push(new AssistantMessage(message));
    return this;
  }

  addErrorMessage(message) {
    this.messages.push(new ChatMessage('openai_error', message));
    return this;
  }

  removeOpenAIErrorMessages() {
    this.messages = this.messages.filter((message) => message.role !== 'openai_error');
  }

  addSummarizeMessage(message) {
    this.messages.push(new ChatMessage('summarize_asset', message));
    return this;
  }

  addCaseStudyMessage(message) {
    this.messages.push(new ChatMessage('asset_case_study', message));
    return this;
  }

  addCustomCaseStudyMessage(message) {
    this.messages.push(new ChatMessage('custom_case_study', message));
    return this;
  }

  addExtractThemesMessage(message) {
    this.messages.push(new ChatMessage('extract_themes', message));
    return this;
  }

  addExtractToneMessage(message) {
    this.messages.push(new ChatMessage('extract_tone', message));
    return this;
  }

  addNewsLetterMessage(message) {
    this.messages.push(new ChatMessage('newsletter_summary', message));
    return this;
  }

  addCustomNewsLetterMessage(message) {
    this.messages.push(new ChatMessage('custom_newsletter_copy', message));
    return this;
  }

  addSlidePresentationMessage(message) {
    this.messages.push(new ChatMessage('slide_presentation', message));
    return this;
  }

  addCustomPresentationSlideMessage(message) {
    this.messages.push(new ChatMessage('custom_presentation_slide', message));
    return this;
  }

  addSocialPostMessage(message) {
    this.messages.push(new ChatMessage('socialpost_summary', message));
    return this;
  }

  addCustomSocialPostMessage(message) {
    this.messages.push(new ChatMessage('custom_socialpost_copy', message));
    return this;
  }

  addCollectionQueryMessage(message) {
    this.messages.push(new ChatMessage('ask_questions_on_collection', message));
    return this;
  }

  addSuggestionsMessage(message) {
    this.messages.push(new ChatMessage('start_off_suggestions', message));
    return this;
  }

  addContentMessage(message) {
    this.messages.push(new ChatMessage('content', message));
    return this;
  }

  addInsightsMessage(message) {
    this.messages.push(new ChatMessage('insights_summary', message));
    return this;
  }

  addCollectionInsightsSummaryMessage(message) {
    this.messages.push(new ChatMessage('collection_insights_summary', message));
    return this;
  }

  addCollectionAssetsSummaryMessage(message) {
    this.messages.push(new ChatMessage('collection_assets_summary', message));
    return this;
  }

  addImageAnalyzerMessage(message) {
    this.messages.push(new ChatMessage('image_analyzer', message));
    return this;
  }

  addCustomShareMessage(message) {
    this.messages.push(new ChatMessage('custom_share_message', message));
  }

  addPromptInstructionsMessage(message) {
    this.messages.push(new ChatMessage('add_prompt_instructions', message));
  }

  addDocumentAnalyzerMessage(message) {
    this.messages.push(new ChatMessage('document_analyzer', message));
    return this;
  }

  addAssistantMarkdownMessage(message) {
    this.messages.push(new ChatMessage('assistant_markdown', message));
    return this;
  }
}

export default AssistantConversation;
