/* eslint-disable-next-line no-bitwise */

const ExternalUrlActionTypes = {
  EMBED: 'embed',
  NEW_WINDOW: 'new window',
  POWER_BI_EMBED: 'power bi embed',
  POWER_BI_EMBED_WITH_ACTIONS_BAR: 'power bi embed w/actions bar',
  POWER_BI_EMBED_WITH_STARFISH_TOOLBAR: 'power bi embed w/starfish toolbar',
  POWER_BI_EMBED_WITH_ACTIONS_AND_STARFISH_TOOLBAR: 'power bi embed w/actions & starfish tool bar',
  TABLEAU_EMBED: 'tableau embed',
  TABLEAU_EMBED_WITH_STARFISH_TOOLBAR: 'tableau embed w/starfish toolbar',
  TABLEAU_EMBED_WITH_ACTIONS_BAR: 'tableau embed w/actions bar',
  TABLEAU_EMBED_WITH_ACTIONS_AND_STARFISH_TOOLBAR: 'tableau embed w/actions & starfish toolbar',
  NONE: 'none',
};

export const ViewTypes = {
  NONE: 0,
  STARFISH_TOOLBAR: 1,
  ADVANCED_STARFISH_TOOLBAR: 2,
  ACTIONS: 4,
  POWERBI: 8,
  TABLEAU: 16,
};

export const EmbedOptions = {
  none: 0,
  'power bi embed': ViewTypes.POWERBI, // 1000

  // eslint-disable-next-line no-bitwise
  'power bi embed w/starfish toolbar': (ViewTypes.POWERBI | ViewTypes.STARFISH_TOOLBAR), // 1001

  // eslint-disable-next-line no-bitwise
  'power bi embed w/actions bar': (ViewTypes.POWERBI | ViewTypes.ACTIONS), // 1100

  // eslint-disable-next-line no-bitwise
  'power bi embed w/actions & starfish tool bar': (ViewTypes.POWERBI | ViewTypes.STARFISH_TOOLBAR | ViewTypes.ACTIONS), // 1101

  'tableau embed': ViewTypes.TABLEAU, // 10000

  // eslint-disable-next-line no-bitwise
  'tableau embed w/starfish toolbar': (ViewTypes.TABLEAU | ViewTypes.STARFISH_TOOLBAR), // 10001,

  // eslint-disable-next-line no-bitwise
  'tableau embed w/actions bar': (ViewTypes.TABLEAU | ViewTypes.ACTIONS), // 10100,

  // eslint-disable-next-line no-bitwise
  'tableau embed w/actions & starfish toolbar': (ViewTypes.TABLEAU | ViewTypes.ACTIONS | ViewTypes.STARFISH_TOOLBAR), // 10101,

  embed: 32,
  new_window: 64,
};

// eslint-disable-next-line no-bitwise
const isTableau = (type) => (EmbedOptions[type] & ViewTypes.TABLEAU) === ViewTypes.TABLEAU;

// eslint-disable-next-line no-bitwise
const isPowerBi = (type) => (EmbedOptions[type] & ViewTypes.POWERBI) === ViewTypes.POWERBI;

// eslint-disable-next-line no-bitwise
const hasTableauActions = (type) => (EmbedOptions[type] & ViewTypes.ACTIONS) === ViewTypes.ACTIONS;

// eslint-disable-next-line no-bitwise
const hasStarfishActions = (type) => (EmbedOptions[type] & ViewTypes.STARFISH_TOOLBAR) === ViewTypes.STARFISH_TOOLBAR;

// eslint-disable-next-line no-bitwise
const hasAdvancedStarfishActions = (type) => (EmbedOptions[type] & ViewTypes.ADVANCED_STARFISH_TOOLBAR) === ViewTypes.ADVANCED_STARFISH_TOOLBAR;

export const ExternalEmbedSystemsChecker = {
  isPowerBi,
  isTableau,
  hasExternalSystemActions: hasTableauActions,
  hasStarfishActions,
  hasAdvancedStarfishActions,
};

export default ExternalUrlActionTypes;
