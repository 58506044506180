import { mapActions } from 'vuex';
import shareMixin from '@/lib/ui/mixins/shareMixin';
import searchMixin from '@/components/search/mixins/searchMixin';
import externalUrlMixin from '@/components/content/mixins/externalUrlMixin';

export default {
  mixins: [
    shareMixin,
    searchMixin,
    externalUrlMixin,
  ],
  data: () => ({
    previewContent: null,
    openModal: false,
    openInsightsModal: false,
  }),
  computed: {
    openPreview: {
      get() {
        return this.openModal;
      },
      set(value) {
        this.openModal = value;
        if (!value) {
          this.previewContent = null;
        }
      },
    },
    showInsight: {
      get() {
        return this.openInsightsModal;
      },
      set(value) {
        this.openInsightsModal = value;
      },
    },
  },
  methods: {
    ...mapActions('discovery', [
      'getDocument',
      'download',
      'openPreviewModalWindow',
      'openContentInsightModalWindow',
      'setAssetDashbordDetails',
    ]),
    ...mapActions('uploader', [
      'openEditMetadata',
      'openAddToCollection',
      'openUploader',
    ]),
    /**
     *
     * @param document {InsightContent|DiscoveryContent|Asset}
     */
    showDocument(content) {
      console.log('Asset mixins, showing preview');
      console.log(content);
      this.openPreviewModalWindow(content);
    },
    onModalClosed() {
      this.openPreview = false;
    },
    /**
     *
     * @param document {InsightContent|DiscoveryContent|Asset}
     */
    showInsightsModal(content) {
      this.openContentInsightModalWindow({
        document: content,
      });
    },
    closeInsight() {
      this.showInsight = false;
    },
    editMetadata(content) {
      // console.log('Edit Metadata', content);

      const docId = content?.id;
      this.openEditMetadata(docId);
    },
    addToCollection(content) {
      const docId = content?.docId;
      this.openAddToCollection(docId);
    },
    gotoAssetDashboard(content) {
      // set state of the external url fields in actions  
      this.setAssetDashbordDetails({
        externalUrl: content.externalUrl,
        externalUrlAction: content.externalUrlAction,
        externalUrlLabel: content.externalUrlLabel,
      });

      // if (this.isNewWindowAction()) {
      //   const externalUrl = this.assetExternalUrl();
      //   this.openLinkInNewTab(externalUrl);
      // } else {
      //   this.$router.push(this.assetEmbedRoute());
      // }

      const externalUrl = this.assetExternalUrl();
      this.openLinkInNewTab(externalUrl);
    },
    openLinkInNewTab(externalUrl) {
      if (!externalUrl) {
        console.error('invalid externalUrl');
        return;
      }
  
      const link = document.createElement('a');
      link.href = externalUrl;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
