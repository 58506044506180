import Vue from 'vue';
import VueRouter from 'vue-router';
import '../setup/auth-provider';

import collectionsRoutes from './collections';
import groupsRoutes from './groups/groups';
import groupDetailsRoute from './groups/groupDetails';

import toolsRoutes from './tools';
import contentRoutes from './content';
import adminRoutes from '../admin/routes';
import dashboardRoutes from './dashboard';
import awardRoutes from './awards';
import collectionDetailsRoute from './collectionDetails';
import biDashboardRoutes from './biDashboard';

const HomeView = () => import('../views/HomeView.vue');
const Search = () => import('../views/SearchView.vue');

const DefaultSearch = () => import('@/views/defaultPages/SearchLandingPage.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'start',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home/:page?',
    name: 'defaultHome',
    components: {
      main: () => import(/* webpackChunkName: "defaultHome" */ '@/views/defaultPages/DefaultHomeLandingPage.vue'),
    },
    meta: {
      requiresAuth: true,
      pageTitle: 'Home',
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    components: {
      default: HomeView,
      main: HomeView,
    },
    meta: {
      hideHeader: true,
      requiresAuth: false,
      hideSearchFromHeader: true,
      pageTitle: 'Login',
    },
  },
  {
    path: '/implicit/callback',
    // component: Auth.handleCallback(),
    components: {
      main: () => import('@/components/okta/DiscoveryCallback.vue'),
    },
    meta: {
      pageTitle: 'Loading...',
    },
  },
  {
    path: '/sites/notfound',
    name: 'siteNotFound',
    components: {
      main: () => import('@/deploymentIndexes/pages/NotFoundOrNoAccess.vue'),
    },
    meta: {
      pageTitle: 'Site Not Found',
    },
  },
  {
    path: '/sites/:indexId',
    components: {
      main: () => import(/* webpackChunkName: "indexHome" */ '@/deploymentIndexes/pages/home/DeploymentIndexHome.vue'),
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'indexHome',
        component: () => import(/* webpackChunkName: "dashboard-pages" */ '../views/Dashboard.vue'),
        redirect: 'home',
        meta: {
          requiresAuth: true,
          hideSearchFromHeader: false,
          showRightPanel: false,
        },
        ...dashboardRoutes,
      },
      {
        path: 'search',
        name: 'indexSearch',
        component: Search,
        meta: {
          requiresAuth: true,
          hideSearchFromHeader: false,
          showRightPanel: false,
          mobileName: 'browse',
          pageTitle: 'Search Results',
        },
      },

      /* indexed collection pages */
      {
        path: 'collections/details/:id',
        name: 'indexCollectionDetails',
        component: () => import(
          /* webpackChunkName: "collections-details" */
          '../components/collections/collectionDetails/CollectionsDetails.vue'
        ),
        meta: {
          editableType: 'collection',
          mobileName: 'collection',
        },
        ...collectionDetailsRoute,
      },
      {
        path: 'collections',
        name: 'indexCollections',
        component: () => import(
          /* webpackChunkName: "collections-list" */
          '@/components/collections/CollectionsHome.vue'
        ),
        meta: {
          collectionType: 'default',
          requiresAuth: true,
          hideSearchFromHeader: false,
          showRightPanel: false,
          mobileName: 'browse',
        },
        ...collectionsRoutes,
      },

      /* indexed group pages */
      {
        path: 'groups/details/:id',
        name: 'indexGroupDetails',
        component: () => import(
          /* webpackChunkName: "group-details" */
          '../components/groups/GroupDetails.vue'
        ),
        meta: {
          editableType: 'group',
          mobileName: 'group',
        },
        ...groupDetailsRoute,
      },
      {
        path: 'groups',
        name: 'indexGroups',
        component: () => import(
          /* webpackChunkName: "groups-index" */
          '../components/groups/GroupsHome.vue'
        ),
        meta: {
          listType: 'default',
        },
        ...groupsRoutes,
      },
      {
        path: 'content/:id?',
        name: 'indexContent',
        component: () => import(/* webpackChunkName: "content-page" */ '@/views/content/ContentPage.vue'),
        meta: {
          requiresAuth: true,
          pageTitle: 'Content Analyzer',
        },
        ...contentRoutes,
      },
      {
        path: 'asset/embed',
        name: 'asset-embed',
        component: () => import(/* webpackChunkName: "content-page" */ '@/components/content/analyzer/pages/AnalyzerEmbeddingPage.vue'),
        meta: {
          requiresAuth: true,
          pageTitle: 'Asset Embedding',
        },
      },
    ],
  },
  {
    path: '/search',
    name: 'Search',
    components: {
      main: DefaultSearch,
    },
    meta: {
      requiresAuth: true,
      hideSearchFromHeader: false,
      showRightPanel: false,
      mobileName: 'browse',
    },
  },

  /* collections landing pages */
  {
    path: '/collections/details/:id/:page?',
    name: 'DefaultCollectionDetails',
    components: {
      main: () => import(/* webpackChunkName: "collections-list" */ '@/views/defaultPages/CollectionDetailsLandingPage.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/collections/:page?',
    name: 'collections-index',
    components: {
      main: () => import(/* webpackChunkName: "collections-list" */ '@/views/defaultPages/CollectionsLandingPage.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },

  /* groups landing pages */
  {
    path: '/groups/details/:id/:page?',
    name: 'GroupDetailsDefault',
    components: {
      main: () => import(
        /* webpackChunkName: "default-collections-details" */
        '@/views/defaultPages/groups/GroupDetailsLandingPage.vue'
      ),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/groups/:page?',
    name: 'groups-index',
    components: {
      main: () => import(
        /* webpackChunkName: "default-groups-list" */
        '@/views/defaultPages/groups/GroupsLandingPage.vue'
      ),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/content/:id?/:page?',
    components: {
      main: () => import(/* webpackChunkName: "default-content-page" */ '@/views/defaultPages/ContentLandingPage.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/kurukkuvazhi',
    components: {
      main: import(/* webpackChunkName: "kurukku" */ '../views/.kurukku/KurukkuPage.vue'),
    },
    meta: {
      requiresAuth: true,
      title: 'Kurukku',
    },
  },
  {
    path: '/experiment',
    components: {
      main: () => import(
        /* webpackChunkName: "experiment" */ '../views/experiment/ExperimentPage.vue'
      ),
    },
    meta: {
      requiresAuth: true,
      title: 'Experiment',
    },
  },
  {
    path: '/video',
    components: {
      main: () => import(/* webpackChunkName: "vi-widget" */ '../views/VideoIndexer.vue'),
    },
    meta: {
      title: 'Video',
    },
  },
  // dashboardRoutes,
  adminRoutes,
  toolsRoutes,
  awardRoutes,
  biDashboardRoutes,
  // ...rightPanelRoutes,
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  /*
    If the user is authenticated, both start and welcome should take the user to dashboard
   */
  if (to.name === 'start') {
    if (await Vue.prototype.$auth.isAuthenticated()) {
      next({ name: 'defaultHome' });
    } else {
      next({ name: 'welcome' });
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  // document.title = to.name;
  document.title = to.meta?.pageTitle || to.name;
  next();
});

router.beforeEach(async (to, from, next) => {
  const auth = Vue.prototype.$auth;
  if (to.matched.some((record) => record.meta.requiresAuth) && !(await auth.isAuthenticated())) {
    auth.login(to.fullPath);
  } else {
    next();
  }
});

router.beforeEach((_, __, next) => {
  window.scrollTo(0, 0);
  next();
});

router.beforeEach((to, from, next) => {
  // Store the previous route in a variable
  if (from.name) {
    localStorage.setItem('previousRoute', from.fullPath);
  }
  next();
});

export default router;
