<template>
  <v-menu v-if="showOpenAi" ref="parentMenuRef" :offset-x="isEmbedSidebar" :nudge-right="isEmbedSidebar ? 15 : 0">
    <template #activator="{ on: onMenu }">
      <v-tooltip :bottom="!isEmbedSidebar" :right="isEmbedSidebar">
        <template #activator="{ on: onTooltip }">
          <v-btn v-if="isEmbedSidebar" tile icon class="menu_marker bottom right"  v-on="{ ...onMenu, ...onTooltip }">
              <v-icon class="ai-stars black" color="#000" style="background-color: black!important;"></v-icon>
            </v-btn>
          <v-btn v-else :ripple="false" class="btn text-uppercase btndownloadmenu
          btn_standout on_toolbar mx-2"
          :class="isGlobalBar ? 'globalbarelement' : 'black--text'"
          v-on="{ ...onMenu, ...onTooltip }" text>
          <v-icon size="32" class="mr-3 ai-stars"
          :class="{black : !isGlobalBar}"
          style="width:24px;height:24px;"></v-icon>
                Interact <v-icon class="material-symbols-outlined">arrow_drop_down</v-icon></v-btn>
        </template>

        <span>{{ isEmbedSidebar ? 'Interact' : 'AI-enabled tools for this content' }}</span>
      </v-tooltip>
    </template>

    <v-list dense>
      <v-list-item @click="openAskBot">
        <v-list-item-title>
          Ask Questions
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="showSummaryPanel">
        <v-list-item-title>
          Summarize
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="showAutomatedInsightsPanel">
        <v-list-item-title>
          Insights
        </v-list-item-title>
      </v-list-item>
      <v-divider/>
      <v-list dense>
        <v-list-item-group dense>
        <v-menu min-width="0" right nudge-right="180" close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              class=""
              v-bind="attrs"
              v-on="on"
              >
              <v-list-item-content>Analysis</v-list-item-content>
              <v-list-item-icon class="ml-2"><v-icon class="material-symbols-outlined">chevron_forward</v-icon></v-list-item-icon>
            </v-list-item>
        </template>
          <v-list dense >
            <v-list-item-group>
              <v-list-item @click="openSubmenu('openAIThemeExtractor')">
                <v-list-item-content>
                  <v-list-item-title>Themes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openSubmenu('openAIToneExtractor')">
                <v-list-item-content>
                  <v-list-item-title>Tones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openSubmenu('openPreviewAnalyzer')">
                <v-list-item-content>
                  <v-list-item-title>Preview Image</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        </v-list-item-group>
      </v-list>
      <v-divider/>
      <v-list dense>
        <v-list-item-group dense>
        <v-menu min-width="0" right nudge-right="180" close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              class=""
              v-bind="attrs"
              v-on="on"
              >
              <v-list-item-content>Writing Assistants</v-list-item-content>
              <v-list-item-icon class="ml-2"><v-icon class="material-symbols-outlined">chevron_forward</v-icon></v-list-item-icon>
            </v-list-item>
        </template>
          <v-list dense >
            <v-list-item-group>
              <v-list-item @click="openSubmenu('openAICaseStudy')">
                <v-list-item-content>
                  <v-list-item-title>Content for Case Study</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openSubmenu('openAISlidePresentation')">
                <v-list-item-content>
                  <v-list-item-title>Content for 1-Slide Presentation</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openSubmenu('openAINewsletter')">
                <v-list-item-content>
                  <v-list-item-title>Title &amp; Summary for Newsletter</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openSubmenu('openAISocialPost')">
                <v-list-item-content>
                  <v-list-item-title>Title &amp; Summary for Social Post</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openSubmenu('openAltTagGenerator')">
                <v-list-item-content>
                  <v-list-item-title>Create Image ALT Tag</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openSubmenu('openImageCaptionGenerator')">
                <v-list-item-content>
                  <v-list-item-title>Create Image Caption</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        </v-list-item-group>
      </v-list>
      <v-list-item disabled v-if="false">
        <v-list-item-title>Translate Text</v-list-item-title>
      </v-list-item>
      <v-list-item disabled v-if="false">
        <v-list-item-title>Analyze Sentiment</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import assetAIInteractionsMenuMixin from '../mixins/assetAIInteractionsMenuMixin';

export default {
  name: 'AssetAiInteractionsMenu',
  mixins: [
    assetAIInteractionsMenuMixin,
  ],
  props: {
    isGlobalBar: {
      type: Boolean,
      default: () => false,
    },
    isEmbedSidebar: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
